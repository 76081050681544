import React, {useState, useEffect, Suspense, lazy} from "react";

const Pc =  lazy(()=>import('./Pc.js'));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Pc/>
      </Suspense>
    </div>
  );
}


export default App;

